import React from 'react'
import theme from 'design'
import { makeIcon } from 'design/components/Icons/Icons'
import { TwoColorIconDefinition } from 'design/components/Icons/types'

export const def: TwoColorIconDefinition = (
  colors = { base: 'currentColor', accent: 'currentColor' }
) => {
  return {
    id: 'sendReminderTwoColor',
    name: 'Send Reminder',
    category: 'misc',
    path: (
      <>
        <path
          clipRule="evenodd"
          d="M14.966 40.77c.062-.149.093-.308.093-.47a1.23 1.23 0 00-.31-.87 1.25 1.25 0 00-1.69-.06l-.06.06-9.44 9.39a1.27 1.27 0 00-.36.88 1.23 1.23 0 001.235 1.231c.328 0 .643-.13.875-.361l9.39-9.4a1.23 1.23 0 00.267-.4zm2.423 7.09L8 57.25a1.26 1.26 0 01-1.75 0 1.23 1.23 0 010-1.74l9.39-9.4.06-.06a1.27 1.27 0 011.69.06 1.24 1.24 0 010 1.75zM27.23 47a1.23 1.23 0 01-.37.88l-9.39 9.39a1.229 1.229 0 01-1.74 0 1.208 1.208 0 010-1.74l9.39-9.4h.05a1.24 1.24 0 011.69 0 1.191 1.191 0 01.37.87z"
          fill={colors.base}
          fillRule="evenodd"
        />
        <path
          d="M58.14 6.18a2.08 2.08 0 00-1.43-.58 2 2 0 00-.63.1L10.71 18.42a3.8 3.8 0 00-2.54 2.93A3.74 3.74 0 009.54 25l3.38 2.68c2.06 1.62 4.42 3.49 6.21 4.92v9.73a2.47 2.47 0 002.45 2.27 2.4 2.4 0 001.57-.6l5.45-3.76 6.07 5 .07.06a3.778 3.778 0 005.5-.79.575.575 0 00.08-.13L58.47 8.75a2.08 2.08 0 000-2.09 2.195 2.195 0 00-.33-.48zm-47.2 17a1.509 1.509 0 01-.55-1.46 1.468 1.468 0 011-1.16l40.84-11.5-31.92 21.56-6-4.75-3.37-2.69zm10.88 19a.47.47 0 00-.12.09.21.21 0 01-.12.05.2.2 0 01-.2-.16v-7.77l5.38 4.37-4.94 3.42zm16.53 1a1.58 1.58 0 01-2.19.27l-.07-.06c-2.49-2-9.62-7.87-13.94-11.34L55.54 9.54 38.35 43.18z"
          fill={colors.accent}
        />
      </>
    ),
    viewBox: '0 0 60 60',
  }
}

export const IconSendReminder = makeIcon(
  def({ base: theme.colors.bb.spicedCrimson, accent: theme.colors.bb.slate })
)
export const IconSendReminderMono = makeIcon(def())
export default IconSendReminder
